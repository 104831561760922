import { Component, OnInit , Input  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { BackendService } from '../../services/backend/backend.service'
import { GlobalService } from '../../services/global/global.service'
import { EventsService } from '../../services/events/events.service'

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.scss']
})
export class PaiementComponent implements OnInit {

  @Input() element
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() title : string = "Modifier l'état du paiement";


  messageRetour
  infosFormGroup: FormGroup;
  submitted = false
  listePaiement: any = [];


  constructor(
    private activeModal: NgbActiveModal ,
    private backendService: BackendService ,
    private globalService: GlobalService,
    private eventsService: EventsService,
    private _formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.listePaiement = this.globalService.getListPaiement();
    this.infosFormGroup = this._formBuilder.group({
      paiementCtrl: [this.element.paiement, Validators.required ]
    });

  }

  ngAfterContentInit() {
    console.log(' this.element :: ', this.element )
    this.title += ` n° ${this.element.numtrans}`
  }

  get f() {
    return this.infosFormGroup.controls;
  }


  public decline() {
    this.activeModal.close({status: false});
  }

  public dismiss() {
    this.decline();
  }


  public valider() {

    this.submitted = true;
    console.log(this.infosFormGroup)
    // stop here if form is invalid
    if (this.infosFormGroup.invalid) {
      return;
    }

    let box = {
      modepaiement: '',
      paiement: this.f.paiementCtrl.value,
      user_direct: !!this.element.user_sender.user_direct
    }

    console.log('la box ', box)

    this.backendService.post(`/senbox/update-paiement-statuts/${this.element.id}`, box )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.messageRetour = {ko: resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."}
        this.eventsService.errorsmsg( this.messageRetour.ko )
      } else {
        this.eventsService.successmsg( resultat.text )
        this.messageRetour = {ok: resultat.text}
        setTimeout( () => {
          this.activeModal.close({status: true});
        }, 1000)
      }
    })
    .catch( error => {
      this.messageRetour = {ko: "Une erreur lors de l'enregistrement des données"}
    })

  }

}
